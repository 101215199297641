import React, { useEffect, useState, useContext } from 'react';

import {
  Card,
  makeStyles,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  TextField,
  CardActions,
  Button
} from '@material-ui/core';

import { currency } from '../../../utils/functions';

import UserContext from '../../../context/UserContext';

import { postItemCotizacion, putCotizacionDet, deleteItemCotizacion } from '../../../services/cotizacionCustom';
//import { getProductByTxt, getProductByCod } from '../../../services/catalogo';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Product = ({ product, idCotizado, refresh, setRefresh }) => {
  console.info("product-", product)
  const classes = useStyles();

  const [codigo, setCodigo] = useState();
  const [titulo, setTitulo] = useState();
  const [descripcion, setDescripcion] = useState();
  const [cantidad, setCantidad] = useState();
  const [precio, setPrecio] = useState();
  const [descuento, setDescuento] = useState();
  const [total, setTotal] = useState();
  const [idCatalogo, setIdCatalogo] = useState();

  const [idCotizacionDet, setIdCotizacionDet] = useState(null)

  const [flagSave, setFlagSave] = useState(true)


  const { user } = useContext(UserContext);

  useEffect(() => {
    setFlagSave(product.precio ? false : true)
    setTitulo(`${product?.product  || product?.producto || product?.titulo || ''} `)
    setDescripcion(`${product?.descripcion || ''} `)
    setCantidad(product?.cantidad || 0)
    setPrecio(product?.precio || 0)
    setDescuento(product?.dcto || 0)
    setTotal(product?.total || 0)
    //setIdCatalogo(product?.catalogo?.id || '')
    setIdCotizacionDet(product?.producto ? product?.id : null)
  }, [product]);

  const handleLimpiar = () => {
    setFlagSave(true)
    setTitulo('')
    setCodigo('')
    setDescripcion('')
    setCantidad(0)
    setPrecio(0)
    setDescuento(0)
    setTotal(0)
    //setIdCatalogo('')
    //setIdCotizacionDet(product?.catalogo ? product?.id : null)
  }

  const handleEliminar = async () => {
    if(idCotizado && product) {
      const {data} = await deleteItemCotizacion(product.id, user.token)
      setRefresh(!refresh)
    }
  }

  const handleClick = async () => {
    if (flagSave) {
      const prod = {
        cantidad,
        dcto: descuento,
        descripcion,
        producto: titulo,
        idCotizadoCustom: idCotizado,
        precio,
        total: calcTotal(),
        codigo,
        titulo
      };
      const {data} = await postItemCotizacion(prod, user.token)
      setRefresh(!refresh)
    } else {
      const cotDet = {
        id: idCotizacionDet,
        titulo,
        producto: titulo,
        descripcion,
        idCatalogo,
        cantidad,
        dcto: descuento,
        idCotizado,
        precio,
        total: calcTotal(),
        codigo  
      }
      const {data} = await putCotizacionDet(cotDet ,user.token)
      setRefresh(!refresh)
    }
    
  }


  const calcTotal = () => {
    return (cantidad * precio) - descuento;
  }


  return (
    <div>
      <Card>
        <CardHeader title={` ${flagSave ? 'AGREGAR PRODUCTO' : 'MODIFICAR DATOS DEL PRODUCTO' }`} />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
          <Grid item md={4} xs={6}>
            <TextField
              fullWidth
              label="CODIGO"
              name="codigo"
              value={codigo}
              onChange={(e) => setCodigo(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="TITULO"
                name="titulo"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="DESCRIPCIÓN"
                name="descripcion"
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
                variant="outlined"
              />
            </Grid>
            
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="CANTIDAD"
                name="cantidad"
                value={cantidad}
                onChange={(e) => setCantidad(e.target.value)}
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="PRECIO"
                name="precio"
                value={precio}
                variant="outlined"
                onChange={(e) => setPrecio(e.target.value)}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="DESCUENTO"
                name="descuento"
                value={descuento}
                variant="outlined"
                onChange={(e) => setDescuento(e.target.value)}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="VALOR FINAL"
                name="total"
                value={currency(calcTotal())}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <CardActions>
            <Button color="primary" onClick={handleLimpiar}>
              LIMPIAR
            </Button>
            <Button color="primary" onClick={handleClick}>
              {flagSave ? "GUARDAR": "GUARDAR"}
            </Button>
            <Button color="primary" onClick={handleEliminar}>
              ELIMINAR
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </div>
  );
};

export default Product;
