import React, { useEffect, useState, useContext } from 'react';

import {
  Card,
  makeStyles,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  TextField,
  CardActions,
  Button
} from '@material-ui/core';

import UserContext from '../../../context/UserContext';

import {
  postItemCotizacion,
  putCotizacionDet
} from '../../../services/cotizacion';
import { getProductByTxt, getProductByCod } from '../../../services/catalogo';
import { deleteItemCotizacionOrigin } from '../../../services/cotizacion';

import { currency } from '../../../utils/functions';
const useStyles = makeStyles(() => ({
  root: {}
}));

const Product = ({ product, idCotizado, refresh, setRefresh }) => {
  const classes = useStyles();

  const [txtProduct, setTxtProduct] = useState();
  const [txtCodProduct, setTxtCodProduct] = useState();

  const [titulo, setTitulo] = useState();
  const [descripcion, setDescripcion] = useState();
  const [cantidad, setCantidad] = useState();
  const [precio, setPrecio] = useState();
  const [descuento, setDescuento] = useState();
  const [total, setTotal] = useState();
  const [idCatalogo, setIdCatalogo] = useState();

  const [idCotizacionDet, setIdCotizacionDet] = useState(null);

  const [flagSave, setFlagSave] = useState(true);

  const { user } = useContext(UserContext);

  useEffect(() => {
    console.info("-product-*", product)
    setFlagSave(product.precio > -1 ? false : true);
    setTitulo(product?.titulo || product?.catalogo?.titulo || '');
    setDescripcion(product?.descripcion || product?.catalogo?.descripcion || '');
    setCantidad(product?.cantidad || 0);
    setPrecio(product?.precio || 0);
    setDescuento(product?.dcto || 0);
    setTotal(product?.total || 0);
    setIdCatalogo(product?.catalogo?.id || '');
    setIdCotizacionDet(product?.catalogo ? product?.id : null);
  }, [product]);

  const handleEliminar = async () => {
    if (idCotizado && product) {
      const { data } = await deleteItemCotizacionOrigin(product?.id, user.token);
      console.info("data", data)
      setRefresh(!refresh);
    }
  };

  const handleLimpiar = () => {
    setFlagSave(true);
    setTitulo('');
    setCantidad(0);
    setPrecio(0);
    setDescuento(0);
    setTotal(0);
    setDescripcion('');
    setIdCatalogo('');
    //setIdCotizacionDet(product?.catalogo ? product?.id : null)
  };


  const calcTotal = () => {
    return cantidad * precio - descuento;
  };

  const handleClick = async () => {
    if (flagSave) {
      const prod = {
        cantidad,
        dcto: descuento,
        idCatalogo,
        idCotizado,
        precio,
        total: calcTotal(),
        titulo,
        descripcion,
      };
      const { data } = await postItemCotizacion(prod, user.token);
      setRefresh(!refresh);
    } else {
      const cotDet = {
        id: idCotizacionDet,
        idCatalogo,
        cantidad,
        dcto: descuento,
        idCotizado,
        precio,
        total: calcTotal(),
        titulo,
        descripcion,
      };
      const { data } = await putCotizacionDet(cotDet, user.token);
      setRefresh(!refresh);
    }
  };

  const keyPress = async e => {
    if (e.keyCode == 13) {
      if (txtProduct) {
        const { data } = await getProductByTxt(txtProduct);
        console.info("data", data)
        if (data.id) {
          setIdCatalogo(data.id);
          setFlagSave(true);
          setTitulo(data?.titulo);
          setCantidad(1);
          setPrecio(data?.precio || 0);
          setDescuento(data?.dcto || 0);
          setTotal(0);
          setDescripcion(data?.descripcion);
        }
      } else {
        if (txtCodProduct) {
          const { data } = await getProductByCod(txtCodProduct);
          console.info("dataCod", data)
          if (data.id) {
            setIdCatalogo(data.id);
            setFlagSave(true);
            setTitulo(data?.titulo);
            setCantidad(1);
            setPrecio(data?.precio || 0);
            setDescuento(data?.dcto || 0);
            setTotal(0);
            setDescripcion(data?.descripcion);
          }
        }
      }
    }
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={` ${
            flagSave ? 'AGREGAR PRODUCTO' : 'MODIFICAR DATOS DEL PRODUCTO'
          }`}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={6}>
              <TextField
                onKeyDown={keyPress}
                fullWidth
                label="Buscar Producto (Nombre)"
                name="busPro"
                value={txtProduct}
                onChange={e => {
                  setTxtCodProduct('');
                  setTxtProduct(e.target.value);
                }}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={6}>
              <TextField
                onKeyDown={keyPress}
                fullWidth
                label="Buscar Producto (Código)"
                name="busPro"
                value={txtCodProduct}
                onChange={e => {
                  setTxtProduct('');
                  setTxtCodProduct(e.target.value);
                }}
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="TITULO"
                name="titulo"
                value={`${titulo}`}
                onChange={e => setTitulo(e.target.value)}
                variant="outlined"
                /*
                InputProps={{
                  readOnly: true
                }}
                */
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="DESCRIPCIÓN"
                name="descripcion"
                value={`${descripcion}`}
                variant="outlined"
                onChange={e => setDescripcion(e.target.value)}
                /*
                InputProps={{
                  readOnly: true
                }}
                */
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="CANTIDAD"
                name="cantidad"
                value={cantidad}
                onChange={e => setCantidad(e.target.value)}
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="PRECIO"
                name="precio"
                value={precio}
                variant="outlined"
                onChange={e => setPrecio(e.target.value)}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="DESCUENTO"
                name="descuento"
                value={descuento}
                variant="outlined"
                onChange={e => setDescuento(e.target.value)}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="VALOR FINAL"
                name="total"
                value={currency(calcTotal())}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <CardActions>
            <Button color="primary" onClick={handleLimpiar}>
              LIMPIAR
            </Button>
            <Button color="primary" onClick={handleClick}>
              {flagSave ? 'GUARDAR' : 'MODIFICAR'}
            </Button>
            <Button color="primary" onClick={handleEliminar}>
              ELIMINAR
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </div>
  );
};

export default Product;
