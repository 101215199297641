import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { getAllCompras } from 'src/services/compras';
import Results from './Results';
import Toolbar from './Toolbar';

import UserContext from '../../../context/UserContext';

import { jsPDF } from 'jspdf';

import imgFond from '../../../assets/imgs/fondo';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = () => {
  const toPdf = () => {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      format: 'letter'
    });

    const img = new Image();
    img.src = imgFond;
    doc.addImage(img, 'png', 0, 0, 600, 460);

    doc.text(20, 20, 'Hello world!');
    doc.text(20, 30, 'This is client-side Javascript to generate a PDF.');

    // Save the PDF
    doc.save('cotizacion.pdf');
  };

  const classes = useStyles();
  const [compras, setCompras] = useState([]);
  const [comprasFilter, setComprasFilter] = useState([]);

  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const getCompras = async () => {
    const { data } = await getAllCompras(user.token);
    setCompras(data);
    setComprasFilter(data);
  };

  useEffect(() => {
    if (user.token) {
      getCompras();
    } else {
      navigate(`/login`, { replace: true });
    }
  }, []);

  return (
    <Page className={classes.root} title="Compras">
      <Container maxWidth={false}>
        <Toolbar
          setValues={setComprasFilter}
          user={user}
          data={compras}
          dataCompra={compras}
        />
        <Box mt={3}>
          <Results data={comprasFilter} />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
