import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  makeStyles
} from '@material-ui/core';

import NavItem from './NavItem';

//import logoWeb from 'src/assets/icons/logo_web.jpg';

import { IconNoticia } from '../../../components/IconSVG/IconSVG';

const items = [
  /*{
    href: '/app/dashboard',
    icon: IconNoticia,
    title: 'Dashboard'
  },*/
  {
    href: '/app/clientes',
    icon: IconNoticia,
    title: 'CLIENTES'
  },
  {
    href: '/app/compras',
    icon: IconNoticia,
    title: 'COMPRAS'
  },
  {
    href: '/app/cotizaciones',
    icon: IconNoticia,
    title: 'COTIZACIONES'
  },
  {
    href: '/app/cotizaciones-custom',
    icon: IconNoticia,
    title: 'COTIZACIONES CUSTOM'
  }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        JOYERIA MARIANI
        {/*<img
          style={{
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            maxHeight: '100%'
          }}
          src={logoWeb}
          alt="logo"
        />*/}
      </Box>
      <Divider />

      <Box p={2}>
        <List>
          {items.map((item: any) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
