import React from 'react';

import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';

interface TypeInputField {
  open: boolean;
  title: any;
  handleClose?: Function;
  duration?: number;
  anchorOrigin?: SnackbarOrigin;
}
function Toast({
  open,
  handleClose,
  title,
  duration = 3000,
  anchorOrigin = { vertical: 'top', horizontal: 'center' }
}: TypeInputField) {
  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={duration}
      onClose={() => handleClose && handleClose()}
    >
      {title}
    </Snackbar>
  );
}

export { Toast };
