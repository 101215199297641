import { getAxioPrivate } from './api';

const path = 'compra/';

export function getAllCompras(token) {
  return getAxioPrivate(token)
    .get(`${path}all`)
    .then(res => res.data);
}

export function postFindCompra(where, token) {
  return getAxioPrivate(token)
    .post(`${path}find`, where)
    .then(res => res.data);
}

export function getCompraById(id, token) {
  return getAxioPrivate(token)
    .get(`${path}${id}`)
    .then(res => res.data);
}
