import { getAxio } from './api';

const path = '';

export function getProductByTxt(txtProducto) {
  return getAxio()
    .get(`${path}producto-by-txt/${txtProducto}`)
    .then(res => res.data);
}


export function getProductByCod(txtCodProducto) {
  return getAxio()
    .get(`${path}producto-by-cod/${txtCodProducto}`)
    .then(res => res.data);
}
