import React, { useEffect, useState } from 'react';

import moment from 'moment';
import ReactToPdf from "react-to-pdf"

import { useNavigate } from 'react-router-dom';

//import imageToBase64 from 'image-to-base64';

import PerfectScrollbar from 'react-perfect-scrollbar';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  TextField
} from '@material-ui/core';
import { currency } from '../../../utils/functions'

import css from './style.module.css';


const useStyles = makeStyles(() => ({
  root: {}
}));

const options = {
  orientation: 'landscape',
  unit: 'cm',
  format: [30, 21]
};

const ComisionDetails = ({
  className,
  compra,
  setLoading,
  setShowToast,
  setMessage,
  setTitleSnack,
  setSeverity,
  comisiones,
  ...rest
}) => {

  const getMaringTop = () => {
    return 340 - (21 * (compra?.compraDets?.length || 1))
  }
  
  const styleMariani = {
    textDecoration: "none !important",
    color: "#fff !important",
    fontFamily: "Lighters",
    fontSize: "36px !important",
    fontWeight: "200 !important",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    cursor: "default"
  }
  
  const styleTxt0 = {
    textDecoration: "none !important",
    color: "#fff !important",
    fontFamily: "Lighters",
    fontSize: "18px !important",
    fontWeight: "300 !important",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    cursor: "default"
  }
  
  const styleTxt02 = {
    textDecoration: "none !important",
    color: "#fff !important",
    fontFamily: "Lighters",
    fontSize: "18px !important",
    fontWeight: "300 !important",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    cursor: "default"
  }

  const classes = useStyles();

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  console.info("compra", compra)
  //const [codigo, setCodigo] = useState('');
  const [cliente, setCliente] = useState('');
  const [fecha, setFecha] = useState('');
  const [tipoCompra, setTipocompra] = useState('');
  const [telefono, setTelefono] = useState('');
  const [addres, setAddres] = useState('');
  const [email, setEmail] = useState('');

  const columns = [
    { txt: 'CÓDIGO', label: 'id' },
    { txt: 'TITULO', label: 'titulo' },
    { txt: 'DESCRIPCIÓN', label: 'descripcion' },
    { txt: 'IMÁGEN', label: 'img' },
    { txt: 'CANTIDAD', label: 'cantidad' },
    { txt: 'PRECIO', label: 'precio' },
    { txt: 'DCTO. %', label: 'dcto' },
    { txt: 'VALOR FINAL', label: 'total' }
  ];


  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setCliente(compra.nameUser);
    setFecha(compra.fecha);
    setTipocompra(compra.txtCompra);
    setTelefono(compra.telefono);
    setAddres(compra.addres);
    setEmail(compra.email);
  });

  console.info("compra", compra)
  
  return (
    <div>
      <Card>
        <CardHeader title="DATOS DE LA COMPRA" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="NOMBRE CLIENTE"
                name="cliente"
                value={cliente}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="FECHA DE COMPRA"
                name="Id"
                value={fecha}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="TIPO DE COMPRA"
                name="Id"
                value={tipoCompra}
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="TELÉFONO"
                name="Id"
                value={telefono}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="DIRECCIÓN"
                name="Id"
                value={addres?.toUpperCase()}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="EMAIL"
                name="Id"
                value={email}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
     {/* 
      <br />
      <br />
      { //showToPDF &&
        <ReactToPdf  filename="Cotizacion.pdf" options={options} >
      
        {({ toPdf, targetRef, options }) => (
            <div onClick={ toPdf } ref={ targetRef } style={
                { 
                  width: "1100px",
                  height: "800px",
                  padding: "20px 40px"
                }
              }>
              
              <table>
              <tr>
                  <th></th>
                  <th></th>
                  <th style={{textAlign: 'center', width: "900px", padding: "40px 0px 0px", ...styleMariani }}>MARIANI ®️</th>
                  <th></th><th></th>
                  <th style={{textAlign: 'center', padding: "40px 0px 0px", ...styleMariani }}>No. {compra.correlativo || '0'}</th>
                </tr>


                <tr>
                  <th></th>
                  <th></th>
                  <th style={{textAlign: 'center', width: "1000px", padding: "0px", ...styleTxt0 }}>JOYERIA</th>
                </tr>

                <tr>
                  <th style={{textAlign: 'center', padding: "40px" }}> </th>
                </tr>

                <tr>
                  <th colspan="6" style={{textAlign: "left"}}>CLIENTE: {cliente}</th>
                </tr>
                <tr>
                  <th colspan="6" style={{textAlign: "left"}}>TIPO DE VENTA: </th>
                </tr>
                <tr>
                  <th colspan="6" style={{textAlign: "left"}}>FECHA: {`${moment().format('MMMM Do YYYY')}`}</th>
                </tr>
                <tr>
                  <th colspan="6" style={{textAlign: "left"}}>TELÉFONO: {telefono}</th>
                </tr>

                <tr>
                  <td colspan="6" style={{paddingTop: '20px'}}>
                    <hr/>
                  </td>
                </tr>

                <tr>
                <th></th>
                <th></th>
                  <th style={{textAlign: 'center', width: "1000px", padding: "40px 0px"}}>DETALLE DE LA COMPRA</th>
                </tr>
                <tr style={{width: "1100px"}}>
                  {
                    //<th style={{maxWidth: "175px", minWidth: "175px"}}>Código</th>
                  }
                  <th style={{maxWidth: '175px', minWidth: '175px'}}>TITULO</th>
                  <th style={{maxWidth: '175px', minWidth: '175px'}}>CANTIDAD</th>
                  <th style={{maxWidth: '175px', minWidth: '175px'}}>PRECIO</th>
                  <th style={{maxWidth: '175px', minWidth: '175px'}}>DCTO.</th>
                  <th style={{maxWidth: '175px', minWidth: '175px'}}>TOTAL</th>
                </tr>

                
                
                {
                  (compra?.compraDets || []).map((row, rowIndex) => {
                    return (
                      <tr style={{width: "1100px"}}>
                        <th style={{width: "175px"}}>{row.idCatalogo}</th>
                        <th style={{width: '175px'}}>{row?.catalogo?.titulo}</th>
                        <th style={{width: '175px'}}>{row.cantidad}</th>
                        <th style={{width: '175px'}}>{row.precio}</th>
                        <th style={{width: '175px'}}>{row.dcto}</th>
                        <th style={{width: '175px'}}>{currency(row.total)}</th>
                      </tr>
                    )
                  })
                }

              </table>
                
              <div style={{marginTop: `${getMaringTop()}px`, width: "1100px", display: "flex"}}>
                
                <div style={{alignItems: "flex-start"}}>
                  <div>TERMINO Y CONDICIONES </div>
                  <div>COTIZACION VALIDA 15 DIAS HABILES </div>
                </div>

                <div style={{marginLeft: "650px"}}>
                  <div>CONTACTO  +56 957991799</div>
                  <div>CONTACTO@JOTERIAMARIANI.CL </div>
                </div>

              </div>

            </div>
        )}
      </ReactToPdf>
      }

      <br />
      */}

      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
          {
            //<button onClick={() => {}}>PRINT</button>
          }
            <Table >
              <TableHead>
                <TableRow>
                  {columns.map(row => {
                    // eslint-disable-next-line no-unused-expressions
                    return <TableCell>{row.txt}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {(compra?.compraDets || [])
                  .slice(page * limit, page > 0 ? (page + 1) * limit : limit)
                  .map(compraDet => (
                    <TableRow hover key={compraDet.id}>
                      {columns.map((row, rowIndex) => {
                        // eslint-disable-next-line no-unused-expressions
                        return row.label === 'img' ? (
                          <TableCell>
                            <img
                              width="100"
                              height="100"
                              src={compraDet.catalogo.img}
                              alt={compraDet.catalogo.titulo}
                            />
                          </TableCell>
                        ) : rowIndex > 3 ? (
                          <TableCell>{compraDet[row.label]}</TableCell>
                        ) : (
                          <TableCell>{compraDet.catalogo[row.label]}</TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={(compra?.compraDets || []).length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
      
      
      <Table id="mytable" style={{display: 'none'}}>
        <TableHead>
          <TableRow>
            {columns.map(row => {
              // eslint-disable-next-line no-unused-expressions
              return <TableCell>{row.txt}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {(compra?.compraDets || [])
            .map(compraDet => (
              <TableRow hover key={compraDet.id}>
                {columns.map((row, rowIndex) => {
                  // eslint-disable-next-line no-unused-expressions
                  return row.label === 'img' ? (
                    <TableCell>
                      <img
                        width="100"
                        height="100"
                        src={compraDet.catalogo.img}
                        alt={compraDet.catalogo.titulo}
                      />
                    </TableCell>
                  ) : rowIndex > 3 ? (
                    <TableCell>{compraDet.[row.label]}</TableCell>
                  ) : (
                    <TableCell>{compraDet.catalogo[row.label]}</TableCell>
                  );
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
          
    </div>
    
  );
};

ComisionDetails.propTypes = {
  className: PropTypes.string,
  comision: PropTypes.array
};

export default ComisionDetails;
