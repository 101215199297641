import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Container, Grid, makeStyles } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import Page from 'src/components/Page';

import { Toast } from 'src/components/Toast/Toast';

import { getCompraById } from 'src/services/compras';

import CompraDetails from './CompraDetail';

import UserContext from '../../../context/UserContext';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Comision = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [compra, setCompra] = useState([]);

  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [severity, setSeverity] = useState('success');

  const { user } = useContext(UserContext);

  const getCompra = async () => {
    if (id !== '0') {
      const { data, all } = await getCompraById(id, user.token);
      setCompra({ ...data, compraDets: all.compraDets });
    }
  };

  useEffect(() => {
    getCompra();
  }, []);

  return (
    <Page className={classes.root} title="Compras">
      <Toast
        open={showToast}
        title={
          <Alert severity={severity}>
            {<AlertTitle>{title}</AlertTitle>}
            {message}
          </Alert>
        }
        handleClose={() => setShowToast(false)}
        duration={2000}
      />
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CompraDetails
              compra={compra || []}
              setLoading={setLoading}
              setTitleSnack={setTitle}
              setShowToast={setShowToast}
              setMessage={setMessage}
              setSeverity={setSeverity}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Comision;
