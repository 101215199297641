import { getAxioPrivate } from './api';

const path = 'cotizacion/';

export function getAllCotizacion(token) {
  return getAxioPrivate(token)
    .get(`${path}all`)
    .then(res => res.data);
}

export function getCotizacionById(id, token) {
  return getAxioPrivate(token)
    .get(`${path}${id}`)
    .then(res => res.data);
}

export function postCotizacion(data, token) {
  return getAxioPrivate(token)
    .post(`${path}`, data)
    .then(res => res.data);
}

export function postItemCotizacion(data, token) {
  return getAxioPrivate(token)
    .post(`${path}item`, data)
    .then(res => res.data);
}


export function postFindCotizacion(where, token) {
  return getAxioPrivate(token)
    .post(`${path}find`, where)
    .then(res => res.data);
}

export function putCotizacionDet(values, token) {
  return getAxioPrivate(token)
    .put(`${path}det`, values)
    .then(res => res.data);
}


export function deleteItemCotizacionOrigin(itemId, token) {
  return getAxioPrivate(token)
    .delete(`${path}item/${itemId}`)
    .then(res => res.data);
}
