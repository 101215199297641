export default () => {
  const token = localStorage.getItem('token')
    ? localStorage.getItem('token')
    : 'null';
  return token;
};

const currency = (number) => {
  //return new Intl.NumberFormat('en-IN', {style: 'currency',currency: 'INR', minimumFractionDigits: 2}).format(number);
  let str = number.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return str.join(".");
} 

const currency2 = (number) => {
  //return new Intl.NumberFormat('en-IN', {style: 'currency',currency: 'INR', minimumFractionDigits: 2}).format(number);
  let str = number.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return str.join(".");
} 

export {
  currency,
  currency2
};
