import React from 'react';
import { Navigate } from 'react-router-dom';

import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';

import UsuariosListView from 'src/views/Usuarios/UsuariosListView';
import UsuariosFormView from 'src/views/Usuarios/UsuarioFormView';

import CompraListView from 'src/views/Compras/CompraListView';
import CompraFormView from 'src/views/Compras/CompraFormView';

import CotizacionesListView from 'src/views/Cotizaciones/CotizacionesListView';
import CotizacionesFormView from 'src/views/Cotizaciones/CotizacionesFormView';

import CotizacionesCustomListView from 'src/views/CotizacionesCustom/CotizacionesListView';
import CotizacionesCustomFormView from 'src/views/CotizacionesCustom/CotizacionesFormView';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'clientes', element: <UsuariosListView /> },
      { path: 'cliente-form/:id', element: <UsuariosFormView /> },

      { path: 'compras', element: <CompraListView /> },
      { path: 'compra-form/:id', element: <CompraFormView /> },
      
      { path: 'cotizaciones', element: <CotizacionesListView /> },
      { path: 'cotizaciones-form/:id', element: <CotizacionesFormView /> },
      
      { path: 'cotizaciones-custom', element: <CotizacionesCustomListView /> },
      { path: 'cotizaciones-custom-form/:id', element: <CotizacionesCustomFormView /> },
      
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
