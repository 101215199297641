import React from 'react';

import { Icon } from '@material-ui/core';
import filter from '../../assets/icons/filter.svg';
import notaPrensa from '../../assets/icons/notaPrensa.svg';
import noticiaComision from '../../assets/icons/noticiaComision.svg';
import compartir from '../../assets/icons/compartir.svg';
import favorito from '../../assets/icons/favorito.svg';
import descargaAma from '../../assets/icons/descargarAma.svg';
import back from '../../assets/icons/iconoBack.svg';

import perfil from '../../assets/icons/perfil.svg';
import gaceta from '../../assets/icons/gaceta.svg';
import consulta from '../../assets/icons/consulta.svg';
import proyectos from '../../assets/icons/proyectos.svg';
import favoritos from '../../assets/icons/favoritos.svg';
import agenda from '../../assets/icons/agenda.svg';
import contacto from '../../assets/icons/contacto.svg';

import cambiarClave from '../../assets/icons/cambiarClave.svg';
import logout from '../../assets/icons/logout.svg';
import eliminarCuenta from '../../assets/icons/eliminarCuenta.svg';

import diputadosSelected from '../../assets/icons/diputados-selected.svg';
import noticiasSelected from '../../assets/icons/noticias-selected.svg';

import settings from '../../assets/icons/settings.svg';

import diputadosFavoritos from '../../assets/icons/diputados-favoritos.png';
import noticiasFavoritas from '../../assets/icons/noticias-favoritas.png';

import iconPlenaria from '../../assets/icons/iconPlenaria.svg';
import iconAgenda from '../../assets/icons/iconAgenda.svg';
import iconComision from '../../assets/icons/iconComision.svg';
import iconDiputado from '../../assets/icons/iconDiputado.svg';
import iconGaceta from '../../assets/icons/iconGaceta.svg';
import iconNotificacion from '../../assets/icons/iconNotificacion.svg';
import iconNota from '../../assets/icons/iconNota.svg';
import iconProyecto from '../../assets/icons/iconProyecto.svg';
import iconUsuario from '../../assets/icons/iconUsuario.svg';
import iconTipo from '../../assets/icons/iconTipo.svg';
import iconVotVon from '../../assets/icons/iconVotCon.svg';
import iconDashboard from '../../assets/icons/iconDashboard.svg';
import iconNoticia from '../../assets/icons/iconNoticia.svg';

import css from './IconSVG.module.css';

export function IconNoticia() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={iconNoticia} alt="icon1" />
    </Icon>
  );
}

export function IconDashboard() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={iconDashboard} alt="icon1" />
    </Icon>
  );
}

export function IconVotCon() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={iconVotVon} alt="icon1" />
    </Icon>
  );
}

export function IconTipo() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={iconTipo} alt="icon1" />
    </Icon>
  );
}

export function IconUsuario() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={iconUsuario} alt="icon1" />
    </Icon>
  );
}

export function IconProyecto() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={iconProyecto} alt="icon1" />
    </Icon>
  );
}

export function IconNotificacion() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={iconNotificacion} alt="icon1" />
    </Icon>
  );
}

export function IconGaceta() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={iconGaceta} alt="icon1" />
    </Icon>
  );
}

export function IconDiputado() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={iconDiputado} alt="icon1" />
    </Icon>
  );
}

export function IconComision() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={iconComision} alt="icon1" />
    </Icon>
  );
}
export function IconAgenda() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={iconAgenda} alt="icon1" />
    </Icon>
  );
}

export function IconPlenaria() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={iconPlenaria} alt="icon1" />
    </Icon>
  );
}

export function IconNota() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={iconNota} alt="icon1" />
    </Icon>
  );
}

export function DescargarAma() {
  return (
    <Icon>
      <img src={descargaAma} alt="icon1" />
    </Icon>
  );
}

export function IconBack() {
  return (
    <Icon className={`${css.BackIcon}`}>
      <img src={back} alt="icon1" />
    </Icon>
  );
}

export function NoticiaComision() {
  return (
    <Icon>
      <img
        style={{ width: '32px !important', height: '32px !important' }}
        src={noticiaComision}
        alt="icon1"
      />
    </Icon>
  );
}
export function Filter() {
  return (
    <Icon>
      <img className={`${css.IconSVG}`} src={filter} alt="icon1" />
    </Icon>
  );
}
export function NotaPrensa() {
  return (
    <Icon>
      <img src={notaPrensa} alt="notaPrensa" />
    </Icon>
  );
}
export function Favorito() {
  return (
    <Icon>
      <img src={favorito} alt="favorito" />
    </Icon>
  );
}
export function Compartir() {
  return (
    <Icon>
      <img src={compartir} alt="compartir" />
    </Icon>
  );
}
export function Perfil() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={perfil} alt="perfil" />
    </Icon>
  );
}
export function Gaceta() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={gaceta} alt="gaceta" />
    </Icon>
  );
}
export function Consulta() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={consulta} alt="consulta" />
    </Icon>
  );
}
export function Proyectos() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={proyectos} alt="proyectos" />
    </Icon>
  );
}
export function Favoritos() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={favoritos} alt="favoritos" />
    </Icon>
  );
}
export function Agenda() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={agenda} alt="agenda" />
    </Icon>
  );
}
export function Contacto() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={contacto} alt="contacto" />
    </Icon>
  );
}
export function CambiarClave() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={cambiarClave} alt="cambiar-clave" />
    </Icon>
  );
}
export function Logout() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={logout} alt="log-out" />
    </Icon>
  );
}
export function EliminarCuenta() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={eliminarCuenta} alt="eliminar-cuenta" />
    </Icon>
  );
}
export function Settings() {
  return (
    <Icon>
      <img src={settings} alt="settings" />
    </Icon>
  );
}
export function DiputadosSelected() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={diputadosSelected} alt="diputados" />
    </Icon>
  );
}
export function NoticiasSelected() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={noticiasSelected} alt="noticias" />
    </Icon>
  );
}

export function Back() {
  return (
    <Icon className={`${css.MasPageIcon}`}>
      <img src={noticiasSelected} alt="noticias" />
    </Icon>
  );
}

export function DiputadosFavoritos() {
  return (
    <Icon className={`${css.FavoritosIcon}`}>
      <img src={diputadosFavoritos} alt="diputados-favoritos" />
    </Icon>
  );
}

export function NoticiasFavoritas() {
  return (
    <Icon className={`${css.FavoritosIcon}`}>
      <img src={noticiasFavoritas} alt="noticias-favoritos" />
    </Icon>
  );
}
