import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { getAllUsers } from 'src/services/users';
import Results from './Results';
import Toolbar from './Toolbar';

import UserContext from '../../../context/UserContext';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = () => {
  const classes = useStyles();
  const [usuarios, setUsuarios] = useState([]);
  const { user, setUser } = useContext(UserContext);

  const navigate = useNavigate();

  const getUsuarios = async () => {
    const { data } = await getAllUsers(user.token);
    setUsuarios(data);
  };

  useEffect(() => {
    if (user.token) {
      getUsuarios();
    } else {
      navigate(`/login`, { replace: true });
    }
  }, []);

  return (
    <Page className={classes.root} title="Usuarios">
      <Container maxWidth={false}>
        <Toolbar setValues={setUsuarios} user={user} data={usuarios} />
        <Box mt={3}>
          <Results data={usuarios} />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
