import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

import { postFindUser } from 'src/services/users';

import exportToExcel from './exportToExcel';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, setValues, user, data = [], ...rest }) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');

  const donwloadData = async () => {
    try {
      const info = [];
      data.forEach(cliente => {
        info.push({
          Rut: cliente.rut,
          Nombre: cliente.nombre,
          Apellido: cliente.apellido,
          Email: cliente.email,
          Telefono: cliente.telefono,
          Direccion: cliente.addres,
          Direccion2: cliente.addres2,
          Comuna: cliente.comuna,
          Comuna2: cliente.comuna2,
          FechaNacimiento: cliente.nac,
          Disponible: cliente.disponible
        });
        /*
        order.orderItems.forEach((orderDet: any, j: number) => {
          if (j === 0) {
            info.push({
              Total: 'Descuento',
              Subtotal: 'SubTotal'
            });
          }
          info.push({
            Estatus: '',
            '# Orden': ''
          });
        });
        */
      });
      exportToExcel(info);
    } catch (error) {
      console.log('errrorr', error);
    }
  };

  const onSearch = async e => {
    e.preventDefault();
    const { data } = await postFindUser({ where: { search } }, user.token);
    setValues(data);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={3}>
        <Card>
          <CardContent>
            <form onSubmit={e => onSearch(e)}>
              <Box maxWidth={500}>
                <TextField
                  onChange={e => setSearch(e.target.value)}
                  value={search}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="BUSCAR CLIENTE"
                  variant="outlined"
                />
              </Box>
            </form>
            <Box display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                variant="contained"
                className={classes.importButton}
                onClick={() => donwloadData()}
              >
                Descargar
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
