import { getAxioAuth } from './api';

const path = '/';

export function logIn(data) {
  return getAxioAuth()
    .post(`admin`, data)
    .then(res => {
      return res.data;
    });
}

export function signUp(user) {
  return getAxioAuth()
    .post(`${path}sing-up`, user)
    .then(res => res.data);
}

export function changePassword(passwordData) {
  return getAxioAuth()
    .put(`${path}/change-password`, passwordData)
    .then(res => res.data);
}

export function recoveryPassword(email) {
  return getAxioAuth()
    .post(`${path}recovery-password`, email)
    .then(res => res.data);
}

export function recoveryAdminPassword(email) {
  return getAxioAuth()
    .post(`${path}recovery-admin-password`, email)
    .then(res => res.data);
}
