import axios from 'axios';

const development = true;
const url = development
  ? 'https://api-joyeria-01.vercel.app/'
  : 'http://localhost:4000/';

export function getAxio() {
  return axios.create({
    baseURL: `${url}public/`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export function getAxioAuth() {
  return axios.create({
    baseURL: `${url}auth/`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export function getAxioPrivate(token) {
  return axios.create({
    baseURL: `${url}api/v1/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    }
  });
}
