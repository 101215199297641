import React, { useState, useEffect, useContext } from 'react';

import { useParams } from 'react-router-dom';

import { Container, Grid, makeStyles } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import Page from 'src/components/Page';

import { Toast } from 'src/components/Toast/Toast';

import { getCotizacionById } from 'src/services/cotizacionCustom';

import CotizacionDetails from './CotizacionDetail';

import UserContext from '../../../context/UserContext';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Cotizaciones = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [cotizacion, setCotizacion] = useState([]);

  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [severity, setSeverity] = useState('success');

  const [refresh, setRefresh] = useState(false);

  const { user } = useContext(UserContext);

  const getCotizacion = async () => {
    if (id !== '0') {
      const { data, all } = await getCotizacionById(id, user.token);
      setCotizacion({ ...data, cotizadoDets: all });
    }
  };

  useEffect(() => {
    getCotizacion();
  }, []);

  useEffect(() => {
    getCotizacion();
  }, [refresh]);

  return (
    <Page className={classes.root} title="Cotizacion">
      <Toast
        open={showToast}
        title={(
          <Alert severity={severity}>
            {<AlertTitle>{title}</AlertTitle>}
            {message}
          </Alert>
        )}
        handleClose={() => setShowToast(false)}
        duration={2000}
      />
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CotizacionDetails
              setRefresh={setRefresh}
              refresh={refresh}
              head={cotizacion}
              compra={cotizacion.cotizadoDets || []}
              setLoading={setLoading}
              setTitleSnack={setTitle}
              setShowToast={setShowToast}
              setMessage={setMessage}
              setSeverity={setSeverity}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Cotizaciones;
