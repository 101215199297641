import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import UserContext from '../../context/UserContext';

import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';

import { Toast } from 'src/components/Toast/Toast';

import Page from 'src/components/Page';
import { logIn, recoveryAdminPassword } from 'src/services/auth';

import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { setUser } = useContext(UserContext);
  const [identificacion, setIdentificacion] = useState('');
  const [clave, setClave] = useState('');
  const [loading, setLoading] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [severity] = useState('success');

  const doLogin = async e => {
    //e.preventDefault();
    setLoading(true);

    const { token, data } = await logIn(
      { rut: identificacion, clave },
      setUser
    );
    console.info('token, data', token, data);
    if (token) {
      setUser({ user: data, token });
      setLoading(false);
      console.info('11111111');
      navigate('/app/clientes', { replace: true });
    } else {
      setMessage(data);
      setTitle('Alerta');
      setShowToast(true);
    }

    setLoading(false);
  };

  const recoveryPass = async e => {
    setLoading(true);
    //console.info('identificacion', identificacion);
    if (identificacion) {
      const { data } = await recoveryAdminPassword({ identificacion });
      if (data[0] === 1) {
        setMessage('Hemos enviado a su email una clave temporal');
        setTitle('Exito');
        setShowToast(true);
        setLoading(false);
      } else {
        setMessage('Ha ocurrido un problema, intentolo nuevamente');
        setTitle('Alerta');
        setShowToast(true);
        setLoading(false);
      }
    } else {
      setMessage(
        'Ingrese su email de acceso para el envio de la clave temporal'
      );
      setTitle('Alerta');
      setShowToast(true);
      setLoading(false);
    }
  };

  return (
    <Page className={classes.root} title="Login">
      <Toast
        open={showToast}
        title={
          <Alert severity={severity}>
            {<AlertTitle>{title}</AlertTitle>}
            {message}
          </Alert>
        }
        handleClose={() => setShowToast(false)}
        duration={2000}
      />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <form onSubmit={e => doLogin(e)}>
            <Box mb={3}>
              <Typography color="textPrimary" variant="h2">
                INGRESA
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                JOYERIA MARIANI
              </Typography>
            </Box>
            <TextField
              fullWidth
              label="IDENTIFICACIÓN"
              margin="normal"
              name="identificacion"
              onChange={e => setIdentificacion(e.target.value)}
              type="text"
              value={identificacion}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="CLAVE"
              margin="normal"
              name="password"
              onChange={e => setClave(e.target.value)}
              type="password"
              value={clave}
              variant="outlined"
            />
            {/*<h6
              style={{ textAlign: 'center', color: 'gray', cursor: 'default' }}
              onClick={() => recoveryPass()}
            >
              ¿Olvidó su contraseña?
            </h6>*/}
            <Box my={2}>
              <Button
                color="primary"
                disabled={loading}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={() => doLogin()}
              >
                INGRESAR
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
