import React, { useEffect, useState, useContext } from 'react';
import ReactToPdf from "react-to-pdf"

/*
import jsPDF from 'jspdf'
import 'jspdf-autotable'
*/

import moment from 'moment';

import PerfectScrollbar from 'react-perfect-scrollbar';

import Product from "./Product"

import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  TextField,
  Button,
  CardActions
} from '@material-ui/core';

import css from './style.module.css';

import { currency, currency2 } from '../../../utils/functions'

import { putCotizacion } from "../../../services/cotizacionCustom"

import UserContext from '../../../context/UserContext';

import { jsPDF } from 'jspdf';
import imgFond from '../../../assets/imgs/fondo';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ComisionDetails = ({
  className,
  compra,
  setLoading,
  setShowToast,
  setMessage,
  setTitleSnack,
  setSeverity,
  comisiones,
  head,
  setRefresh,
  refresh,
  ...rest
}) => {
  const classes = useStyles();

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  //const [codigo, setCodigo] = useState('');
  const [cliente, setCliente] = useState('');
  const [fecha, setFecha] = useState('');
  const [tipoCompra, setTipocompra] = useState('');
  const [telefono, setTelefono] = useState('');
  const [addres, setAddres] = useState('');
  const [email, setEmail] = useState('');
  const [observacion, setObservacion] = useState('');
  const [showToPDF, setShowToPDF] = useState(false);

  const options = {
    orientation: 'landscape',
    unit: 'cm',
    format: [30, 21]
};

  const [productSelected, setProductSelected] = useState({})

  const { user } = useContext(UserContext);

  const columns = [
    { txt: 'CÓDIGO', label: 'codigo' },
    { txt: 'TITULO', label: 'producto' },
    { txt: 'DESCRIPCIÓN', label: 'descripcion' },
    { txt: 'CANTIDAD', label: 'cantidad' },
    { txt: 'PRECIO', label: 'precio' },
    { txt: 'DCTO.', label: 'dcto' },
    { txt: 'VALOR FINAL', label: 'total' }
  ];

  const sliceTxt = (txt, cantidad=38) => {
    return `${txt.substr(0, cantidad)} ${txt.length<= cantidad ? '' : '...'}`;
  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  
  useEffect(() => {
    //console.info("compra", compra, head)
    setCliente(compra.nombre);
    setFecha(compra.createdAt);
    setTipocompra(compra.tipoCompra);
    setTelefono(compra.telefono);
    setAddres(compra.addres);
    setEmail(compra.email);
    setObservacion(compra.observacion)
  }, [head, compra]);

  const handleUpd = async () => { 
    const values = {
      id: compra.id,
      nombre: cliente,
      tipoCompra,
      email,
      telefono,
      observacion
    }
    //console.info("values", values, user.token)

    const { data } = await putCotizacion(values, user.token);
  }

const getMaringTop = () => {
  return 475 - (43 * (compra?.cotizadoDetCustoms?.length || 1))
} 

const lineClamp = {
  
  "-webkit-line-clamp": "1  ",
  "-webkit-box-orient": "vertical",
  overflow: "hidden"
}

const styleMariani = {
  textDecoration: "none !important",
  color: "#fff !important",
  fontFamily: "Lighters",
  fontSize: "36px !important",
  fontWeight: "300 !important",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  cursor: "default"
}

const styleTxt0 = {
  textDecoration: "none !important",
  color: "#fff !important",
  fontFamily: "Lighters",
  fontSize: "18px !important",
  fontWeight: "300 !important",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  cursor: "default"
}

const styleTxt02 = {
  textDecoration: "none !important",
  color: "#fff !important",
  fontFamily: "Lighters",
  fontSize: "18px !important",
  fontWeight: "300 !important",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  cursor: "default"
}

const toPdf = () => {
  //console.info("compra?.cotizadoDets || []")
  
  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'px',
    format: 'letter'
  });

  
  doc.setFont("Nexa");

  doc.setFontSize(12);

  const getLongi = (text) => {
    return doc.getTextDimensions(text).w
  }

  const calcC = (init, fin, longi) => {
    const x = init + longi
    const y = fin - x
    return Math.ceil((y / 2 )+ init)
  }
  

  
  
  const img = new Image();
  img.src = imgFond;
  doc.addImage(img, 'png', 0, 0, 600, 460);
  doc.setFontSize(18);
  
  doc.text(267, 25, 'MARIANI');
  doc.setFontSize(12);
  doc.text(330, 22, '®');
  doc.text(520, 25, 'COTIZACIÓN');
  doc.setFontSize(10);
  doc.text(284, 35, 'JOYERIA');
  doc.setFontSize(12);
  doc.text(551, 40, `NO. ${compra?.correlativo || 0}`);

  doc.text(15, 70, `CLIENTE: ${cliente.toUpperCase()}`);
  doc.text(15, 85, `TIPO DE VENTA: ${tipoCompra.toUpperCase()}`) ;
  doc.text(15, 100, `FECHA: ${moment().format('MMMM Do YYYY')}`);
  doc.text(15, 115, `TELÉFONO: ${telefono}`);

  doc.text(15, 125, '____________________________________________________________________________________________________________________________');

  
  doc.setFontSize(12).setFont(undefined, 'bold');

  doc.text(calcC(20, 65, getLongi("CÓDIGO")), 150, `CÓDIGO`);
  doc.text(calcC(65, 190, getLongi("PRODUCTO")), 150, `PRODUCTO`);
  doc.text(calcC(195, 445, getLongi("DESCRIPCIÓN")),  150, `DESCRIPCIÓN`);
  doc.text(calcC(450, 515, getLongi("CANTIDAD")), 150, `CANTIDAD`);
  doc.text(calcC(520, 580, getLongi("TOTAL")), 150, `TOTAL`);

  doc.setFontSize(12).setFont(undefined, 'normal');

  let alto01 = 0;
  let total = 0;
  (compra?.cotizadoDetCustoms || []).forEach((compraDet, indexx) => {
    const textCodigo = `${compraDet.codigo}`;
    const textTitleProd  = `${sliceTxt((compraDet?.producto), 43 )}`;
    const textDescripcion  = `${sliceTxt((compraDet?.descripcion ), 200)}`;
    const textCantidad  = `${compraDet.cantidad}`;
    const textTotal  = `${currency2(compraDet.total)}`;

    const longiCodigo = getLongi(textCodigo)
    const longiTitleProd = getLongi(textTitleProd)
    const longiDescripcion = getLongi(textDescripcion)
    const longiCantidad = getLongi(textCantidad)
    const longiTotal = getLongi(textTotal)
console.info("-Math.ceil(longiDescripcion/47)-", textDescripcion.length, Math.ceil(textDescripcion.length/47))

    doc.text(calcC(20, 65, longiCodigo), (165 + (indexx * 55)), `${textCodigo}`);
    doc.text(calcC(65, 190, longiTitleProd/2), (165 + (indexx * 55)), `${textTitleProd}`, {maxWidth: 130});
    doc.text(calcC(195, 270, longiDescripcion/Math.ceil(longiDescripcion/47)), (165 + (indexx * 55)), `${textDescripcion}`, {maxWidth: 250});
    doc.text(calcC(450, 515, longiCantidad), (165 + (indexx * 55)), `${textCantidad}`);
    doc.text(calcC(520, 580, longiTotal), (165 + (indexx * 55)), `${currency2(textTotal)}`);
    alto01 = indexx 
    total += compraDet.total
  });
  const textTotalFinal = `${currency2(total)}`;
  const longiTotalFinal = getLongi`${currency2(total)}`;

    
    doc.setFontSize(12).setFont(undefined, 'bold');
    doc.text(calcC(500, 520, getLongi("TOTAL:")), (333), `TOTAL:`);
    doc.setFontSize(12).setFont(undefined, 'normal');
    
    doc.text(calcC(515, 550, longiTotalFinal), (333), `${currency2(textTotalFinal)}`);  
    doc.setFontSize(10).setFont(undefined, 'normal');

    doc.text(15, 350, 'Observación');
    doc.text(15, 360, sliceTxt(compra.observacion, 250), {maxWidth: 560});
  
  doc.text(15, 425, 'TERMINOS Y CONDICIONES');
  doc.text(15, 440, 'COTIZACIÓN VÁLIDA POR 15 DÍAS HÁBILES');
  doc.text(425, 425, 'CONTACTO +56 957991799');
  doc.text(425, 440, 'CONTACTO@JOYERIAMARIANI.CL');

  // Save the PDF
  doc.save('cotizacion.pdf');

  

}

  return (
    <div>
      <Card>
        <CardHeader title="DATOS DE LA COMPRA" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="NOMBRE CLIENTE"
                name="cliente"
                value={cliente}
                variant="outlined"
                onChange={(e) => setCliente(e.target.value)}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="FECHA DE COMPRA"
                name="Id"
                value={fecha}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="TIPO DE COMPRA"
                name="Id"
                value={tipoCompra}
                variant="outlined"
                onChange={(e) => setTipocompra(e.target.value)}
              />
            </Grid>

            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="TELÉFONO"
                name="Id"
                value={telefono}
                variant="outlined"
                onChange={(e) => setTelefono(e.target.value)}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="DIRECCION"
                name="Id"
                value={addres}
                variant="outlined"
                onChange={(e) => setAddres(e.target.value)}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="EMAIL"
                name="Id"
                value={email}
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="Observación"
                name="Observación"
                value={observacion}
                variant="outlined"
                onChange={(e) => setObservacion(e.target.value)}
              />
            </Grid>
          </Grid>
          <CardActions>
            <Button color="primary" onClick={handleUpd} variant="contained">
              ACTUALIZAR.
            </Button>
          </CardActions>
        </CardContent>
      </Card>
      <br />
      <Product 
        setRefresh={setRefresh}
        refresh={refresh}
        product={productSelected}
        idCotizado={compra.id} 
      />
      <br />
      
      
      { showToPDF &&
        <ReactToPdf  filename="Cotizacion.pdf" options={options} >
      
        {({ toPdf, targetRef, options }) => (
            <div onClick={ toPdf } ref={ targetRef } style={
                { 
                  width: "1100px",
                  height: "800px",
                  padding: "20px 40px",
                  "backgroundImage": 'url("https://joyeriamariani.cl/fotos_joyeria_mariani/fondo.png")',
                  "fontFamily": "arial"
                }
              }>

              <table >
                <tr>
                  <th colspan="1"></th>
                  <th colspan="9" style={{textAlign: 'center', marginTop:"40px", ...styleMariani }}>MARIANI ®️</th>
                  <th colspan="2" style={{textAlign: 'center', marginTop:"40px",...styleMariani }}>COTIZACIÓN No. {compra.correlativo || '0'}</th>
                </tr>

                <tr>
                  <th colspan="1"></th>
                  <th colspan="9" style={{textAlign: 'center', marginTop:"40px", ...styleTxt0 }}>JOYERIA</th>
                </tr>

                <tr>
                  <th style={{textAlign: 'center', padding: "20px" }}> </th>
                </tr>

                <tr>
                  <th colspan="6" style={{textAlign: 'left'}}>CLIENTE: {cliente}</th>
                </tr>
                <tr>
                  <th colspan="6" style={{textAlign: 'left'}}>TIPO DE VENTA: </th>
                </tr>
                <tr>
                  <th colspan="6" style={{textAlign: 'left'}}>FECHA: {`${moment().format('MMMM Do YYYY').toUpperCase()}`}</th>
                </tr>
                <tr>
                  <th colspan="6" style={{textAlign: 'left'}}>TELÉFONO: {telefono}</th>
                </tr>

                <tr>
                  <td colspan="6" style={{padding: '20px 0px'}}>
                    <hr/>
                  </td>
                </tr>


                <tr style={{width: '1100px', ...lineClamp }}>
                  <th style={{width: '220px', ...lineClamp }}>CÓDIGO</th>
                  <th style={{width: '330px', ...lineClamp }}>PRODUCTO</th>
                  <th style={{width: '330px', ...lineClamp }}>DESCRIPCIÓN</th>
                  <th style={{width: '220px', ...lineClamp }}>CANTIDAD</th>
                  <th style={{width: '220px'}}>TOTAL</th>
                </tr>
                
                {
                  (compra?.cotizadoDetCustoms || [])
                  .map(compraDet => (
                    <tr style={{width: "1100px"}}>
                      <th style={{width: '220', ...lineClamp }}>{compraDet.codigo}</th>
                      <th style={{width: '330px', ...lineClamp }}>{sliceTxt(compraDet?.producto)}</th>
                      <th style={{width: '330px', ...lineClamp }}>{sliceTxt(compraDet?.descripcion)}</th>
                      <th style={{width: '220px', ...lineClamp }}>{compraDet.cantidad}</th>
                      <th style={{width: '220px', ...lineClamp }}>{currency2(compraDet.total)}</th>
                    </tr>
                  ))
                }

              </table>
              <div style={{marginTop: `${getMaringTop()}px`, width: "1000px", display: "flex"}}>
                
                <div style={{alignItems: "flex-start"}}>
                  <div>TERMINOS Y CONDICIONES</div>
                  <div>COTIZACIÓN VALIDA 15 DIAS HÁBILES</div>
                </div>

                <div style={{marginLeft: "auto"}}>
                  <div>CONTACTO  +56 957991799</div>
                  <div>CONTACTO@JOYERIAMARIANI.CL </div>
                </div>

              </div>
                
            </div>
        )}
      </ReactToPdf>
      }
      
     

      <Card className={clsx(classes.root, className)} {...rest}>
                  <PerfectScrollbar>
                    <Box minWidth={1050}>
                    {/*<button onClick={descargar}>print</button>*/}

                      <Table >
                        <TableHead>
                          <TableRow>
                            {columns.map(row => {
                              // eslint-disable-next-line no-unused-expressions
                              return <TableCell>{row.txt}</TableCell>;
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(compra?.cotizadoDetCustoms || [])
                            .slice(page * limit, page > 0 ? (page + 1) * limit : limit)
                            .map(compraDet => (
                              <TableRow hover key={compraDet.id} onClick={() => {setProductSelected(compraDet)} }>
                                {columns.map((row, rowIndex) => {
                                  // eslint-disable-next-line no-unused-expressions
                                  //console.info("row", row, compraDet)
                                  return row.label === 'img' ? (
                                    <TableCell>
                                      <img
                                        width="100"
                                        height="100"
                                        src={compraDet.producto}
                                        alt={compraDet.producto}
                                      />
                                    </TableCell>
                                  ) : rowIndex > 3 ? (
                                    <TableCell>{ currency2(compraDet.[row.label]) }</TableCell>
                                  ) : (
                                    <TableCell>{compraDet.[row.label]}</TableCell>
                                  );
                                })}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => toPdf()}
                      >
                        VER PDF
                      </Button>
                    </Box>
                  </PerfectScrollbar>
                  <TablePagination
                    component="div"
                    count={(compra?.cotizadoDetCustoms || []).length}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25]}
                  />
                </Card>

                <Table id="mytable" style={{display: 'none'}}>
                <TableHead>
                  <TableRow>
                    {columns.map(row => {
                      // eslint-disable-next-line no-unused-expressions
                      return <TableCell>{row.txt}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(compra?.cotizadoDetCustoms || [])
                    .map(compraDet => (
                      <TableRow hover key={compraDet.id}>
                        {columns.map((row, rowIndex) => {
                          // eslint-disable-next-line no-unused-expressions
                          return row.label === 'img' ? (
                            <TableCell>
                              <img
                                width="100"
                                height="100"
                                src={compraDet.producto}
                                alt={compraDet.producto}
                              />
                            </TableCell>
                          ) : rowIndex > 3 ? (
                            <TableCell>{compraDet[row.label]}</TableCell>
                          ) : (
                            <TableCell>{compraDet.producto}</TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                </TableBody>
                </Table>
                

    </div>
    
  );
};

ComisionDetails.propTypes = {
  className: PropTypes.string,
  comision: PropTypes.array
};

export default ComisionDetails;
