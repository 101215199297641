import { getAxioPrivate } from './api';

const path = 'usuario/';

export function postFindUser(data, token) {
  return getAxioPrivate(token)
    .post(`${path}find`, data)
    .then(res => res.data);
}

export function getAllUsers(token) {
  return getAxioPrivate(token)
    .get(`${path}all`)
    .then(res => res.data);
}

export function getAllUsersWEB(token) {
  return getAxioPrivate(token)
    .get(`${path}web/all`)
    .then(res => res.data);
}

export function getUserById(idUsuario, token) {
  return getAxioPrivate(token)
    .get(`${path}${idUsuario}`)
    .then(res => res.data);
}

export function getUserDetail(idUser) {
  return getAxioPrivate()
    .get(`${path}id/${idUser}`)
    .then(res => res.data);
}

export function putUser(data, token) {
  return getAxioPrivate(token)
    .put(`usuario`, data)
    .then(res => res.data);
}

export function deleteUser(idUser, token) {
  return getAxioPrivate(token)
    .delete(`${path}${idUser}`)
    .then(res => res.data);
}

export function putUserAdmin(data) {
  return getAxioPrivate()
    .put(`${path}user/admin`, data)
    .then(res => res.data);
}

export function getUserAdminById(idUserAdmin) {
  return getAxioPrivate()
    .get(`${path}user/admin/${idUserAdmin}`)
    .then(res => res.data);
}
