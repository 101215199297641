import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const ComisionDetails = ({
  className,
  usuario,
  updateUsuario,
  deleteUsuario,
  setLoading,
  setShowToast,
  setMessage,
  setTitleSnack,
  setSeverity,
  comisiones,
  ...rest
}) => {
  const classes = useStyles();
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [email, setEmail] = useState('');
  const [rut, setRut] = useState('');

  const [nac, setNac] = useState('');
  const [addres1, setAddres1] = useState('');
  const [comuna1, setComuna1] = useState('');
  const [addres2, setAddres2] = useState('');
  const [comuna2, setComuna2] = useState('');
  const [phone, setPhone] = useState('');
  const [genero, setGenero] = useState('');

  const [disponible, setDisponible] = useState('');

  const navigate = useNavigate();

  const updateValues = async values => {
    setLoading(true);
    await updateUsuario(values);
    setMessage('los datos fueron actualizados');
    setTitleSnack('Exito');
    setSeverity('success');
    setLoading(false);
    setShowToast(true);
    navigate(`/app/clientes`, { replace: true });
  };

  const deleteValues = async idUsuario => {
    setLoading(true);
    await deleteUsuario(idUsuario);
    setMessage('El usuario ha sido eliminado');
    setTitleSnack('Exito');
    setSeverity('success');
    setLoading(false);
    setShowToast(true);
    navigate(`/app/clientes`, { replace: true });
  };

  useEffect(() => {
    setNombre(usuario?.nombre);
    setApellido(usuario?.apellido);
    setEmail(usuario?.email);
    setRut(usuario?.identificacion);
    setDisponible(usuario?.disponible);
    setNac(usuario?.nac);
    setAddres1(usuario?.addres);
    setComuna1(usuario?.comuna);
    setAddres2(usuario?.addres2);
    setComuna2(usuario?.comuna2);
    setPhone(usuario?.telefono);
    setGenero(usuario?.genero);
  }, [usuario]);

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader title="DATOS DEL CLIENTE" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="NOMBRE"
                name="nombre"
                onChange={event => setNombre(event.target.value)}
                required
                value={nombre}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="APELLIDO"
                name="apellido"
                onChange={event => setApellido(event.target.value)}
                required
                value={apellido}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="EMAIL"
                name="email"
                onChange={event => setEmail(event.target.value)}
                required
                value={email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="RUT"
                name="rut"
                onChange={event => setRut(event.target.value)}
                required
                value={rut}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="FECHA DE NACIMIENTO"
                name="nac"
                onChange={event => setNac(event.target.value)}
                required
                value={nac}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="DIRECCION (PARTICULAR)"
                name="addres"
                onChange={event => setAddres1(event.target.value)}
                required
                value={addres1}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="COMUNA"
                name="comuna"
                onChange={event => setComuna1(event.target.value)}
                required
                value={comuna1}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="DIRECCIÓN (TRABAJO)"
                name="addres2"
                onChange={event => setAddres2(event.target.value)}
                required
                value={addres2}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="COMUNA"
                name="comuna2"
                onChange={event => setComuna2(event.target.value)}
                required
                value={comuna2}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="CELULAR"
                name="phone"
                onChange={event => setPhone(event.target.value)}
                required
                value={phone}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="GÉNERO"
                name="genero"
                onChange={event => setGenero(event.target.value)}
                required
                value={genero}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      disponible === 1 ||
                      disponible === '1' ||
                      disponible === true
                        ? true
                        : false
                    }
                    onChange={event => setDisponible(event.target.checked)}
                    name="disponible"
                    color="primary"
                  />
                }
                label="DISPONIBLE"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />

        <Box display="flex" justifyContent="flex-end" p={2}>
          <div>
            {usuario.id && (
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.importButton}
                  onClick={() =>
                    updateValues({
                      nombre,
                      apellido,
                      disponible,
                      rut,
                      email,
                      nac,
                      addres: addres1,
                      comuna: comuna1,
                      addres2,
                      comuna2,
                      telefono: phone,
                      genero,
                      id: usuario.id
                    })
                  }
                >
                  Actualizar
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.importButton}
                  onClick={() => deleteValues(usuario.id)}
                >
                  ELIMINAR
                </Button>
              </div>
            )}
          </div>
        </Box>
      </Card>
    </form>
  );
};

ComisionDetails.propTypes = {
  className: PropTypes.string,
  comision: PropTypes.array
};

export default ComisionDetails;
