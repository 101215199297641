import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

import { postFindCompra } from 'src/services/compras';

import exportToExcel from './exportToExcel';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, setValues, user, dataCompra = [], ...rest }) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [txtCliente, setTxtCliente] = useState('');

  const onSearch = async e => {
    e.preventDefault();
    const newData = [];
    dataCompra.forEach(item => {
      let flag = false;

      if (item.nameUser.includes(txtCliente) && txtCliente) {
        flag = true;
      }
      if (item.id.includes(search) && search) {
        flag = true;
      }
      if (flag || (search === '' && txtCliente === '')) {
        newData.push(item);
      }
    });
    //const { data } = await postFindCompra({ where: { search } }, user.token);
    setValues(newData);
  };

  const donwloadData = async () => {
    try {
      console.info("---", dataCompra)
      const info = [];
      dataCompra.forEach(compra => {
        info.push({
          Codigo: compra.id,
          Cliente: compra.nameUser,
          Rut: compra.rutUser
        });

        compra.compraDets.forEach((det: any, j: number) => {
          if (j === 0) {
            info.push({
              Codigo: 'CÓDIGO',
              Titulo: 'TITULO'
            });
          }
          info.push({
            Codigo: det.id,
            Titulo: det.catalogo.titulo
          });
        });
      });
      exportToExcel(info);
    } catch (error) {
      console.log('errrorr', error);
    }
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={3}>
        <Card>
          <CardContent>
            <form onSubmit={e => onSearch(e)}>
              <Box maxWidth={500}>
                <TextField
                  onChange={e => setSearch(e.target.value)}
                  value={search}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="BUSCAR POR CÓDIGO"
                  variant="outlined"
                />
                <TextField
                  onChange={e => setTxtCliente(e.target.value)}
                  value={txtCliente}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="BUSCAR POR CLIENTE"
                  variant="outlined"
                />
              </Box>
            </form>
            {
              <Box display="flex" justifyContent="flex-end">
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.importButton}
                  onClick={e => onSearch(e)}
                >
                  BUSCAR
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.importButton}
                  onClick={() => donwloadData()}
                >
                  DESCARGAR
                </Button>
              </Box>
            }
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
