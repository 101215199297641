import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { Suspense } from 'react';

import { useRoutes } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core';

import GlobalStyles from 'src/components/GlobalStyles';

import 'src/mixins/chartjs';

import theme from 'src/theme';
import routes from 'src/routes';

import { UserContextProvider } from './context/UserContext';

import './App.css';

const App = () => {
  const routing = useRoutes(routes);

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={null}>
        <UserContextProvider>
          <GlobalStyles />
          {routing}
        </UserContextProvider>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
