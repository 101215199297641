import React from 'react';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import InputIcon from '@material-ui/icons/Input';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const asyncLocalStorage = {
    setItem: function(key, value) {
      return Promise.resolve().then(function() {
        localStorage.setItem(key, value);
      });
    },
    getItem: function(key) {
      return Promise.resolve().then(function() {
        return JSON.parse('' + localStorage.getItem(key));
      });
    }
  };

  const logOut = () => {
    localStorage.setItem('token', null);
    localStorage.setItem('tkn', null);
    navigate('/login', { replace: true });
  };

  const irPerfil = () => {
    console.info('perfil');
    navigate('/app/perfil', { replace: true });
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <Box flexGrow={1} />
        {/*<IconButton color="inherit">
          <Avatar src="/broken-image.jpg" onClick={() => irPerfil()} />
        </IconButton>*/}
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <IconButton color="inherit">
          <InputIcon onClick={() => logOut()} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
