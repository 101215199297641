import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { getAllCotizacion } from 'src/services/cotizacionCustom';
import Results from './Results';
import Toolbar from './Toolbar';

import UserContext from '../../../context/UserContext';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CotizacionesListView = () => {
  const classes = useStyles();
  const [cotizaciones, setCotizaciones] = useState([]);
  const [cotizacionesFilter, setCotizacionesFilter] = useState([]);

  const [refresh, setRefresh] = useState(false);

  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const getCotizaciones = async () => {
    const { data } = await getAllCotizacion(user.token);
    setCotizaciones(data);
    setCotizacionesFilter(data);
  };

  useEffect(() => {
    if (user.token) {
      getCotizaciones();
    } else {
      navigate(`/login`, { replace: true });
    }
  }, []);

  useEffect(() => {
    getCotizaciones();
  }, [refresh]);

  return (
    <Page className={classes.root} title="Cotizaciones-custom">
      <Container maxWidth={false}>
        <Toolbar
          refresh={refresh}
          setRefresh={setRefresh}
          setValues={setCotizacionesFilter}
          user={user}
          data={cotizaciones}
          dataCompra={cotizaciones}
        />
        <Box mt={3}>
          <Results data={cotizacionesFilter} />
        </Box>
      </Container>
    </Page>
  );
};

export default CotizacionesListView;
