import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, data, ...rest }) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const navigate = useNavigate();

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const columns = [
    { txt: 'NOMBRE', label: 'nombre' },
    { txt: 'APELLIDO', label: 'apellido' },
    { txt: 'EMAILS', label: 'email' },
    { txt: 'TELÉFONO', label: 'telefono' }
  ];

  const handleClickUsuario = usuario => {
    navigate(`/app/cliente-form/${usuario.id}`, { replace: true });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(row => {
                  // eslint-disable-next-line no-unused-expressions
                  return <TableCell>{row.txt}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * limit, page > 0 ? (page + 1) * limit : limit)
                .map(usuario => (
                  <TableRow
                    hover
                    key={usuario.id}
                    onClick={() => handleClickUsuario(usuario)}
                  >
                    {columns.map(row => {
                      // eslint-disable-next-line no-unused-expressions
                      return <TableCell>{usuario[row.label]}</TableCell>;
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={data.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  noticias: PropTypes.array.isRequired
};

export default Results;
